:root {
    --orange: #e54c38;
    --blue: #191923;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

h1 {
    font-size: clamp(34px, 3.33vw, 48px);
}

h2 {
    font-size: clamp(28px, 2.63vw, 34px);
}

h3 {
    font-size: clamp(24px, 1.94vw, 28px);
}

body {
    padding-top: 80px;
    background-color: var(--blue);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
}

.round-button, .contact-btn {
    border-radius: 25px;
    padding: 8px 12px;
    cursor: pointer;
    border: none;
    transition: 0.3s;
    max-width: fit-content;
    text-decoration: none;
}

input {
    font-size: 16px;
    border: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

textarea {
    font-size: 16px;
    margin-top: 10px;
    width: 100%;
    max-width: 400px;
    border: none;
    resize: none;
    padding: 10px;
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.btn-orange {
    color: #000;
    background-color: var(--orange);
    border: 1px solid var(--orange);
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
}

.btn-orange:active, .btn-orange:hover {
    background-color: var(--blue);
    color: #FFF;
}

.btn-blue {
    color: #FFF;
    background-color: var(--blue);
    border: 1px solid var(--blue);
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
}

.btn-blue:active, .btn-blue:hover {
    color: var(--blue);
    background-color: var(--orange);
}

.btn-red {
    color: #000;
    background-color: red;
    border: 1px solid red;
}

.btn-red:active {
    background-color: red;
    color: #FFF;
}

.btn-green {
    color: #000;
    background-color: green;
    border: 1px solid green;
    color: #FFF
}

.btn-green:active {
    background-color: green;
    color: #FFF;
}

.container {
    width: 1440px;
    max-width: 100%;
    margin: 0 auto;
}

.row {
    display: flex;
}

.col {
    display: flex;
    flex-direction: column;
}

.gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
}

.gallery-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.space-between {
    justify-content: space-between;
}

.align-center {
    align-items: center;
}

.align-end {
    align-items: flex-end;
}

.justify-end {
    justify-content: flex-end;
}


.pbxs {padding-bottom: 15px;}
.pbs {padding-bottom: 30px;}
.pbm {padding-bottom: 40px;}
.pbl {padding-bottom: 80px;}
.pbxl {padding-bottom: 150px;}


.ptxs {padding-top: 15px;}
.pts {padding-top: 30px;}
.ptm {padding-top: 40px;}
.ptl {padding-top: 80px;}
.ptxl {padding-top: 150px;}


.text-white {
    color: #FFF;
}

.text-navy {
    color: var(--blue);
}

button{
    color: var(--orange);
}

.text-center {
    text-align: center;
}

.bg-orange {
    background-color: var(--orange);
}

.half-width {
    width: calc(50% - 40px);
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.br15 {
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}

.mw540 {
    max-width: 540px;
    width: 100%;
}

.mw900 {
    max-width: 900px;
    width: 100%;
}

.mw600 {
    max-width: 600px;
    width: 100%;
}

.mw700 {
    max-width: 700px;
    width: 100%;
}

.invis {
    opacity: 0;
}

form {
    width: 100%;
}

/* Header */
nav {
    width: 100%;
    height: 80px;
    position: fixed;
    top: 0;
    z-index: 99;
    display: flex;
    justify-content: center;
    background-color: var(--blue);
    border-bottom: 1px solid var(--orange);
}
.nav-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1440px;
}

.main-logo {
    width: 100px;
    height: 60px;
}

.main-logo img {
    width: 100%;
    height: auto;
}

nav ul {
    display: flex;
    list-style: none;
    gap: 20px;
}

nav a {
    color: #fff;
    text-decoration: none;
}
.hamburger {
    display: none;
}
/* Footer */
footer {
    border-top: 1px solid var(--orange);
    width: 100%;
    height: auto;
    padding: 20px 0;
    display: flex;
    justify-content: center;
}
.footer-wrapper {
    max-width: 1440px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

footer p, footer a {
    font-size: 14px;
}
.link-wrapper a,
.link-wrapper p,
.white-link {
    color: #fff;
    text-decoration: none;
    transition: 0.3s;
}

.link-wrapper a:hover {
    color: var(--orange);
}

.link-wrapper ul {
    padding: 0;
    margin: 0;
    padding-left: 1em;
    list-style-type: '- ';
}

.gap5 {
    gap: 5px;
}


.gap10 {
    gap: 10px;
}

.hidden {
    display: none !important;
}


/* Ribbon */
.ribcon {
    position: relative;
}
.ribcon::before {
    content: '';
    top: 0;
    left: 0;
    z-index: -1;
    position: absolute;
    background-color: var(--orange);
    width: 100%;
    height: 100%;
    transform: skewY(2deg);
    -webkit-transform: skewY(2deg);
    -moz-transform: skewY(2deg);
    -ms-transform: skewY(2deg);
    -o-transform: skewY(2deg);
}
.ribcon-list {
    padding-left: 1em;
}
.ribcon-list li {
    padding-bottom: 10px;
    font-weight: 600;
}

/* Slider */
.slider-container {
    max-width: 80%;
    overflow: hidden;
    border-collapse: separate;
}

.carousel-img {
    width: 100%;
    height: 100%;
    aspect-ratio: 4/3;
    background-position: center center;
    background-size: cover;
}

/* ADMIN STYLES */


/** LOGIN PAGE **/

.login-body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    margin: 0;
}

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    background-color: #fff;
    height: 70%;
    max-height: 570px;
    width: 90%;
    max-width: 400px;
    padding: 70px 0 30px;
    border-radius: 10px;
    box-shadow: 0 0 15px 0 var(--orange);
}

.login-container h3 {
    font-size: 20px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
}

.login-container form {
    display: flex;
    flex-direction: column;
    max-width: 350px;
    width: 90%;
}

.login-container label {
    font-size: 15px;
    margin-left: 2px;
}

.login-container input {
    padding: 10px;
    border: 1px solid black;
    border-radius: 5px;
    margin-top: 2px;
}

.login-container input:first-of-type {
    margin-bottom: 25px;
}

.login-container button {
    margin-top: 10px;
    align-self: center;
}

.login-container img {
    width: 60%;
    height: auto;
}

.reset-password {
    color: #FF0000;
    font-size: 12px !important;
    margin-top: 5px;
    margin-right: 5px;
    align-self: end;
}

.failed-login {
    color: #FF0000;
    padding: 15px;
    align-self: center;
    text-align: center;
}

.reset-password-sent {
    color: #07bc0c;
    padding: 15px;
    align-self: center;
    text-align: center;
}

.reset-password-container {
    max-height: 525px !important;
}

.confirm-reset-password-container {
    max-height: 600px !important;
}

/** DASHBOARD PAGE **/

.dashboard-boxes {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-evenly;
    margin-top: 20px;
}

.dashboard-box {
    width: 300px;
    height: 280px;
    background-color: var(--orange);
    padding: 75px 0;
    text-align: center;
    border-radius: 10px;
    text-decoration: none;
    color: #000;
}

.dashboard-box h3 {
    font-size: 25px;
    font-weight: lighter;
    padding-bottom: 10px;
}

.dashboard-box h1 {
    font-size: 80px;
}

.dashboard-container {
    background-color: white;
    border-radius: 10px;
    margin-top: 30px;
    height: 700px;
}

/** MEMBERS / PENIDNG MEMBERS PAGES */
.database-container {
    background-color: white;
    border-radius: 10px;
    margin-top: 30px;
    height: 80vh;
}

.settings-container {
    height: 400px !important;
}


.database th {
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    font-weight: bold;
}

.database td {
    border-bottom: 1px solid black;
    border-right: 1px solid black;
}

.database a {
    text-decoration: none;
    margin-right: 10px;
}


/* NAV STROKE */
nav li a {
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
}
nav li:not(:last-child) a {
    border-bottom: 1px solid transparent;
}
nav li:not(:last-child) a:hover {
    border-color: #fff;
}
nav li:last-child a:hover {
    background-color: var(--blue);
    color: var(--orange);
}

/* MEMBERSHIP PAGE */
.member-h3 {
    text-align: center;
    max-width: 360px;
    width: 90%;
}
.member-input,
#contact input {
    margin-top: 10px;
    padding: 10px;
    max-width: 400px;
    width: 100%;
}

.form-div {
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.button-wrapper {
    display: flex;
    gap: 20px;
}