@media screen and (max-width : 1500px) {
    .container, nav, footer {
        padding-left: 5%;
        padding-right: 5%;
    }
}

@media screen and (max-width : 1000px) {
    .t-col {
        flex-direction: column;
    }
    .t-pts {padding-top: 30px;}
    .t-ptm {padding-top: 40px;}
    .half-width {
        width: 100%;
    }
    .gallery {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width : 680px) {
    .gallery {
        grid-template-columns: repeat(1, 1fr);
    }
    body, input, textarea {
        font-size: 14px;
    }
    #membership input,
    #contact input,
    #contact textarea {
        width: 90%;
    }

    .slider-container {
        max-width: 100%;
    }
    .navlink-wrapper {
        position: fixed;
        width: 102%;
        height: 100vh;
        background-color: var(--blue);
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        flex-wrap: wrap;
        top: 50%;
        left: 50%;
        transform: translate(-151%, -50%);
        -webkit-transform: translate(-151%, -50%);
        -moz-transform: translate(-151%, -50%);
        -ms-transform: translate(-151%, -50%);
        -o-transform: translate(-151%, -50%);
        transition: 0.3s;
        -webkit-transition: 0.3s;
        -moz-transition: 0.3s;
        -ms-transition: 0.3s;
        -o-transition: 0.3s;
}
    .show-navlink {
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
}
    nav ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 18px;
    }
    nav li:last-child {
        margin-top: 10px;
    }
    .hamburger {
        position: relative;
        display: flex;
        align-items: center;
        width: 30px;
        height: 24px;
        cursor: pointer;
    }
    .hamburger::before,
    .hamburger::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 2px;
        background-color: #fff;
        transition: 0.3s;
        -webkit-transition: 0.3s;
        -moz-transition: 0.3s;
        -ms-transition: 0.3s;
        -o-transition: 0.3s;
}
    .hamburger::before {
        top: 0;
    }
    .hamburger::after {
        bottom: 0;
    }
    .ham-icon {
        position: relative;
        pointer-events: none;
        width: 100%;
        height: 2px;
        background-color: #fff;
        transition: 0.3s;
        -webkit-transition: 0.3s;
        -moz-transition: 0.3s;
        -ms-transition: 0.3s;
        -o-transition: 0.3s;
    }

    .hamAnim::before {
        top: 11px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
}
    .hamAnim::after {
        bottom: 11px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
}

.m-fullwidth {
    width: 100%;
}

.footer-wrapper,
.link-wrapper {
    flex-direction: column;
    width: 100%;
    gap: 20px;
}

.footer-wrapper .align-end {
    align-items: flex-start;
}

    
}